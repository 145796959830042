

































































import Vue from 'vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import { getElasticSearchClient } from '@/services/elasticSearch'
import {
  getMarket,
  setDefaultCurrencyMarketProductPrices,
  setMarketCurrencyMarketProductPrices,
  updateMarketPrice,
} from '@/lib/market'
import { getProduct } from '@/lib/product'
import {
  CurrencyEnum,
  Market,
  MarketPriceVariantInput,
  Product,
} from '../../../../../shared/types/types'
import CurrencyInput from '@/components/shared/CurrencyInput.vue'
import getSymbolFromCurrency from 'currency-symbol-map'

export default Vue.extend({
  name: 'MarketVariants',
  components: {
    NavigateBack,
    CurrencyInput,
  },
  data() {
    return {
      loading: false,
      market: null,
      product: null,
      variants: [],
      priceFields: [
        'price',
        'discountedPrice',
        'internalPrice',
        'upsellPrice',
        'discountedUpsellPrice',
        'internalUpsellPrice',
        'packagePrice',
        'discountedPackagePrice',
        'internalPackagePrice',
      ],
      useDefaultCurrency: false,
    } as {
      loading: boolean
      market: Market | null
      product: Product | null
      variants: Record<string, any>[]
      priceFields: [
        'price',
        'discountedPrice',
        'internalPrice',
        'upsellPrice',
        'discountedUpsellPrice',
        'internalUpsellPrice',
        'packagePrice',
        'discountedPackagePrice',
        'internalPackagePrice'
      ]
      useDefaultCurrency: boolean
    }
  },
  async mounted() {
    const elasticSearch = getElasticSearchClient()
    const [market, product, variants] = await Promise.all([
      getMarket(this.$route.params.marketId),
      getProduct(this.$route.params.productId),
      elasticSearch.post('/market_variants/_search', {
        size: 100,
        query: {
          bool: {
            must: [
              {
                match: {
                  marketId: this.$route.params.marketId,
                },
              },
              {
                match: {
                  productId: this.$route.params.productId,
                },
              },
            ],
          },
        },
        sort: {
          'title.keyword': 'asc',
        },
      }),
    ])

    this.market = market
    this.product = product
    this.variants = variants.data?.hits?.hits?.map(
      (data: Record<string, any>) => {
        return data._source
      }
    )
    this.useDefaultCurrency =
      this.variants[0].price.currency === CurrencyEnum.Usd
  },
  methods: {
    getSymbolFromCurrency,
    setDefaultCurrency(value: boolean) {
      console.log(value)
      this.useDefaultCurrency = value

      this.variants.forEach((variant) => {
        this.priceFields.forEach((priceField) => {
          variant[priceField].currency = value
            ? CurrencyEnum.Usd
            : this.market?.currency || CurrencyEnum.Usd
        })
      })
    },
    async handleSave() {
      this.loading = true

      try {
        await updateMarketPrice(this.$route.params.marketId, {
          products: [],
          variants: this.variants.map((variant) => {
            const prices = this.priceFields.reduce(
              (acc, priceField) => {
                acc[priceField] = {
                  ...variant[priceField],
                  amount: Number(variant[priceField].amount),
                }

                return acc
              },
              {
                price: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                discountedPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                internalPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                upsellPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                discountedUpsellPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                internalUpsellPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                packagePrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                discountedPackagePrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                internalPackagePrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
              }
            )

            const variantInput: MarketPriceVariantInput = {
              productId: this.$route.params.productId,
              variantId: variant.variantId,
              ...prices,
            }
            return variantInput
          }),
        })

        this.$buefy.toast.open({
          type: 'is-success',
          position: 'is-bottom',
          message: 'Variant pricing updated',
        })
      } catch (err) {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: 'Something went wrong',
        })
      }

      this.loading = false
    },
    async useOriginalDefaultPrice() {
      if (!this.market || !this.product) return

      this.$buefy.toast.open({
        type: 'is-light',
        position: 'is-bottom',
        message: `Resetting ${this.product.title} to USD price...`,
      })

      try {
        await setDefaultCurrencyMarketProductPrices(this.market.id, {
          productIds: [this.product.productId!],
        })

        this.$buefy.toast.open({
          type: 'is-success',
          position: 'is-bottom',
          message: `${this.product.title} succesfully reset to USD price`,
        })
      } catch (err) {
        console.error(err)
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: `An error occured!`,
        })
      }
    },
    async useMarketDefaultPrice() {
      if (!this.market || !this.product) return

      this.$buefy.toast.open({
        type: 'is-light',
        position: 'is-bottom',
        message: `Resetting ${this.product.title} to ${this.market.currency} price...`,
      })

      try {
        await setMarketCurrencyMarketProductPrices(this.market.id, {
          productIds: [this.product.productId!],
        })

        this.$buefy.toast.open({
          type: 'is-success',
          position: 'is-bottom',
          message: `${this.product.title} succesfully reset to ${this.market.currency} price`,
        })
      } catch (err) {
        console.error(err)
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: `An error occured!`,
        })
      }
    },
  },
})
