import apolloClient from '@/services/apollo'
import {
  CREATE_MARKET,
  DELETE_MARKET,
  RESET_MARKET_PRODUCT_PRICE,
  UPDATE_MARKET,
  UPDATE_MARKET_PRICE,
  SET_DEFAULT_CURRENCY_MARKET_PRODUCT_PRICES,
  SET_MARKET_CURRENCY_MARKET_PRODUCT_PRICES,
  UPDATE_MARKET_COUNTRIES,
} from '@/graphql/mutation/market'
import { GET_MARKET } from '@/graphql/query/market'
import {
  DeleteSuccess,
  MarketSuccess,
  Market,
  Maybe,
  MarketInput,
  MutationCreateMarketArgs,
  MutationDeleteMarketArgs,
  MutationUpdateMarketArgs,
  MutationResetMarketProductPriceArgs,
  QueryGetMarketArgs,
  MarketPriceInput,
  MutationUpdateMarketPriceArgs,
  DefaultCurrencyMarketInput,
  MutationSetDefaultCurrencyMarketProductPricesArgs,
  MutationSetMarketCurrencyMarketProductPricesArgs,
  MarketCountriesInput,
  MutationUpdateMarketCountriesArgs,
} from '../../../shared/types/types'

export const getMarket = async (id: string): Promise<Maybe<Market>> => {
  const { data } = await apolloClient.query<
    {
      getMarket: Maybe<Market>
    },
    QueryGetMarketArgs
  >({
    query: GET_MARKET,
    variables: {
      id,
    },
  })

  return data.getMarket
}

export const createMarket = async (
  market: MarketInput
): Promise<Maybe<Market>> => {
  const result = await apolloClient.mutate<
    {
      createMarket: Market
    },
    MutationCreateMarketArgs
  >({
    mutation: CREATE_MARKET,
    variables: {
      market,
    },
  })

  return result.data?.createMarket || null
}

export const updateMarket = async (
  id: string,
  market: MarketInput
): Promise<Maybe<Market>> => {
  const result = await apolloClient.mutate<
    {
      updateMarket: Market
    },
    MutationUpdateMarketArgs
  >({
    mutation: UPDATE_MARKET,
    variables: {
      id,
      market,
    },
  })

  return result.data?.updateMarket || null
}

export const deleteMarket = async (
  id: string
): Promise<Maybe<DeleteSuccess>> => {
  const result = await apolloClient.mutate<
    {
      deleteMarket: DeleteSuccess
    },
    MutationDeleteMarketArgs
  >({
    mutation: DELETE_MARKET,
    variables: {
      id,
    },
  })

  return result.data?.deleteMarket || null
}

export const updateMarketPrice = async (
  id: string,
  input: MarketPriceInput
): Promise<Maybe<MarketSuccess>> => {
  const result = await apolloClient.mutate<
    {
      sucessful: MarketSuccess
    },
    MutationUpdateMarketPriceArgs
  >({
    mutation: UPDATE_MARKET_PRICE,
    variables: {
      id,
      input,
    },
  })

  return result.data?.sucessful || null
}

export const updateMarketCountries = async (
  id: string,
  input: MarketCountriesInput
): Promise<Maybe<MarketSuccess>> => {
  const result = await apolloClient.mutate<
    {
      sucessful: MarketSuccess
    },
    MutationUpdateMarketCountriesArgs
  >({
    mutation: UPDATE_MARKET_COUNTRIES,
    variables: {
      id,
      input,
    },
  })

  return result.data?.sucessful || null
}

export const resetMarketProductPrice = async (
  id: string,
  productId: string
): Promise<Maybe<MarketSuccess>> => {
  const result = await apolloClient.mutate<
    {
      sucessful: MarketSuccess
    },
    MutationResetMarketProductPriceArgs
  >({
    mutation: RESET_MARKET_PRODUCT_PRICE,
    variables: {
      id,
      productId,
    },
  })

  return result.data?.sucessful || null
}

export const setDefaultCurrencyMarketProductPrices = async (
  id: string,
  input: DefaultCurrencyMarketInput
): Promise<Maybe<MarketSuccess>> => {
  const result = await apolloClient.mutate<
    {
      sucessful: MarketSuccess
    },
    MutationSetDefaultCurrencyMarketProductPricesArgs
  >({
    mutation: SET_DEFAULT_CURRENCY_MARKET_PRODUCT_PRICES,
    variables: {
      id,
      input,
    },
  })

  return result.data?.sucessful || null
}

export const setMarketCurrencyMarketProductPrices = async (
  id: string,
  input: DefaultCurrencyMarketInput
): Promise<Maybe<MarketSuccess>> => {
  const result = await apolloClient.mutate<
    {
      sucessful: MarketSuccess
    },
    MutationSetMarketCurrencyMarketProductPricesArgs
  >({
    mutation: SET_MARKET_CURRENCY_MARKET_PRODUCT_PRICES,
    variables: {
      id,
      input,
    },
  })

  return result.data?.sucessful || null
}
