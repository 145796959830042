





















































import Vue from 'vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import currencyCodes from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
import CountriesModal from '@/components/shared/CountriesModal.vue'
import { data as countriesData } from '@/assets/countries.json'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { createMarket } from '@/lib/market'
import { CurrencyEnum } from '../../../../../shared/types/types'

export default Vue.extend({
  name: 'NewMarket',
  components: {
    NavigateBack,
  },
  data() {
    return {
      market: {
        name: '',
        currency: CurrencyEnum.Usd,
        countries: [] as string[],
      },
      currencies: currencyCodes.data.map((currency) => ({
        name: currency.currency,
        code: currency.code,
        symbol: getSymbolFromCurrency(currency.code),
      })),
      loading: false,
    }
  },
  methods: {
    openSelectCountryModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CountriesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          selectedCountryCodes: this.market.countries,
        },
        events: {
          save: async (
            selectedCountryCodes: string[],
            closeModal: () => void
          ) => {
            this.market.countries = selectedCountryCodes
            closeModal()
          },
        },
      })
    },
    getCountryFlag(countryCode: string) {
      return getUnicodeFlagIcon(countryCode)
    },
    getCountryName(countryCode: string) {
      return countriesData.find((country) => country.code === countryCode)?.name
    },
    async handleClick() {
      console.log('hey')
      try {
        console.log('1')
        this.loading = true
        console.log('2')

        const market = await createMarket({
          title: this.market.name,
          currency: this.market.currency,
          countries: this.market.countries.map((countryCode) => ({
            name: countriesData.find((country) => country.code === countryCode)!
              .name,
            code: countryCode,
          })),
        })

        this.$router.push(`/settings/markets/${market?.id}`)
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    },
  },
})
