











































import Vue from 'vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import { getElasticSearchClient } from '@/services/elasticSearch'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

export default Vue.extend({
  name: 'Markets',
  components: {
    NavigateBack,
  },
  data() {
    return {
      markets: [],
    }
  },
  async mounted() {
    const elasticSearch = getElasticSearchClient()
    const { data } = await elasticSearch.post('/markets/_search', { size: 100 })
    this.markets = data.hits?.hits?.map(
      (data: Record<string, any>) => data._source
    )
  },
  methods: {
    getCountryFlag(countryCode: string) {
      return getUnicodeFlagIcon(countryCode)
    },
  },
})
