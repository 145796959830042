import gql from 'graphql-tag'

/**
 * Query to get a market for the specified marketId
 */
export const GET_MARKET = gql`
  query getMarket($id: ID!) {
    getMarket(id: $id) {
      id
      title
      currency
      countries {
        name
        code
      }
    }
  }
`
