














































































































import Vue from 'vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import currencyCodes from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
import ItemImage from '@/components/shared/ItemImage.vue'
import CountriesModal from '@/components/shared/CountriesModal.vue'
import { ImageSizeEnum } from '@/types'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { data as countriesData } from '@/assets/countries.json'
import {
  deleteMarket,
  getMarket,
  resetMarketProductPrice,
  setDefaultCurrencyMarketProductPrices,
  setMarketCurrencyMarketProductPrices,
  updateMarket,
  updateMarketCountries,
  updateMarketPrice,
} from '@/lib/market'
import { getElasticSearchClient } from '@/services/elasticSearch'
import {
  Country,
  CurrencyEnum,
  Market,
} from '../../../../../shared/types/types'
import pluralize from 'pluralize'

export default Vue.extend({
  name: 'Market',
  components: {
    NavigateBack,
    ItemImage,
  },
  data() {
    return {
      ImageSizeEnum,
      CurrencyEnum,
      market: null,
      products: [] as Record<string, any>,
      currencies: currencyCodes.data.map((currency) => ({
        name: currency.currency,
        code: currency.code,
        symbol: getSymbolFromCurrency(currency.code),
      })),
      countries: [] as Country[],
      deleting: false,
      loading: false,
      resetLoading: {},
    } as {
      ImageSizeEnum: any
      CurrencyEnum: any
      market: Market | null
      products: Record<string, any>[]
      currencies: { name: string; code: string; symbol: string }[]
      deleting: boolean
      loading: boolean
      resetLoading: Record<string, any>
      countries: Country[]
    }
  },
  async mounted() {
    const elasticSearch = getElasticSearchClient()
    const [market, products] = await Promise.all([
      getMarket(this.$route.params.id),
      elasticSearch.post('/market_products/_search', {
        size: 100,
        query: {
          match: {
            marketId: this.$route.params.id,
          },
        },
        sort: {
          'title.keyword': 'asc',
        },
      }),
    ])

    this.market = market
    this.countries = market?.countries || []
    this.products = products.data?.hits?.hits
      .map((data: Record<string, any>) => ({
        ...data._source,
        ...(!data._source.price && {
          price: {
            amount: '',
            currency: this.market?.currency || CurrencyEnum.Usd,
          },
        }),
      }))
      .map((product: Record<string, any>) => {
        if (product.price.amount) {
          product.price.amount = product.price.amount / 100
        }

        return product
      })
  },
  methods: {
    getSymbolFromCurrency,
    pluralize,
    useDefaultCurrenyForProduct(id: string, value: boolean) {
      const product = this.products.find((product) => product.productId === id)

      if (!product) return

      product.useDefaultCurrency = value
      product.price.currency = value
        ? CurrencyEnum.Usd
        : this.market?.currency || CurrencyEnum.Usd
    },
    handleCurrencyChange(currency: string) {
      Object.assign({}, this.market, {
        currency,
      })
      this.products.forEach((product) => {
        if (product.price.currency !== CurrencyEnum.Usd) {
          product.price.currency = currency
        }
      })
    },
    getCountryFlag(countryCode: string) {
      return getUnicodeFlagIcon(countryCode)
    },
    getCountryName(countryCode: string) {
      return countriesData.find((country) => country.code === countryCode)?.name
    },
    openSelectCountryModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CountriesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          selectedCountryCodes: this.countries.map((data) => data.code) || [],
        },
        events: {
          save: async (
            selectedCountryCodes: string[],
            closeModal: () => void
          ) => {
            this.countries = countriesData.filter((data) =>
              selectedCountryCodes.includes(data.code)
            )

            closeModal()
          },
        },
      })
    },
    async handleDelete() {
      try {
        this.deleting = true

        await deleteMarket(this.$route.params.id)

        this.$buefy.toast.open({
          message: 'Market succesfully deleted',
          position: 'is-bottom',
          type: 'is-success',
        })

        this.$router.push('/settings/markets')
      } catch (err) {
        console.error(err)

        this.$buefy.toast.open({
          message: 'Something went wrong.',
          position: 'is-bottom',
          type: 'is-danger',
        })
      }

      this.deleting = true
    },
    async handleSave() {
      this.loading = true

      try {
        await Promise.all([
          updateMarketCountries(this.$route.params.id, {
            countries: this.countries.map((data) => ({
              code: data.code,
              name: data.name,
            })),
          }),
          updateMarketPrice(this.$route.params.id, {
            products: this.products
              .filter((product) => !!product.price.amount)
              .map((product) => ({
                productId: product.productId,
                price: {
                  ...product.price,
                  amount: Number(product.price.amount) * 100,
                },
              })),
            variants: [],
          }),
        ])

        this.$buefy.toast.open({
          message: 'Market has been updated!',
          type: 'is-success',
          position: 'is-bottom',
        })
      } catch (err) {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: 'Something went wrong',
        })
      }

      this.loading = false
    },
    async resetProductPrice(productId: string) {
      const product = this.products.find(
        (product) => product.productId === productId
      )

      if (!product) return

      Vue.set(this.resetLoading, productId, true)

      product.price.amount = ''

      await resetMarketProductPrice(this.$route.params.id, productId)

      this.$buefy.toast.open({
        type: 'is-success',
        position: 'is-bottom',
        message: 'Price reset succesfully',
      })

      Vue.set(this.resetLoading, productId, false)
    },
    resetClasses(productId: string) {
      return {
        'opacity-50 pointer-events-none': this.resetLoading[productId],
      }
    },
    isProductUsingDefaultCurrency(product: Record<string, any>) {
      return product.price.currency === CurrencyEnum.Usd
    },
    async useOriginalDefaultPrice(product: Record<string, any>) {
      if (!this.market) return

      this.$buefy.toast.open({
        type: 'is-light',
        position: 'is-bottom',
        message: `Resetting ${product.title} to USD price...`,
      })

      try {
        await setDefaultCurrencyMarketProductPrices(this.market.id, {
          productIds: [product.productId],
        })
        product.currentCurrency = this.market.currency

        this.$buefy.toast.open({
          type: 'is-success',
          position: 'is-bottom',
          message: `${product.title} succesfully reset to USD price`,
        })
      } catch (err) {
        console.error(err)
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: `An error occured!`,
        })
      }
    },
    async useMarketDefaultPrice(product: Record<string, any>) {
      if (!this.market) return

      this.$buefy.toast.open({
        type: 'is-light',
        position: 'is-bottom',
        message: `Resetting ${product.title} to ${this.market.currency} price...`,
      })

      try {
        await setMarketCurrencyMarketProductPrices(this.market.id, {
          productIds: [product.productId],
        })
        product.currentCurrency = CurrencyEnum.Usd

        this.$buefy.toast.open({
          type: 'is-success',
          position: 'is-bottom',
          message: `${product.title} succesfully reset to ${this.market.currency} price`,
        })
      } catch (err) {
        console.error(err)
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: `An error occured!`,
        })
      }
    },
    shouldDisableOriginalCurrency(product: Record<string, any>) {
      return this.market?.currency !== product.currentCurrency
    },
    shouldDisableMarketCurrency(product: Record<string, any>) {
      return this.market?.currency === product.currentCurrency
    },
  },
})
