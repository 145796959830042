import gql from 'graphql-tag'

/**
 * Mutation to delete a market for the specified marketId
 */
export const DELETE_MARKET = gql`
  mutation deleteMarket($id: ID!) {
    deleteMarket(id: $id) {
      isDeleteSuccess
    }
  }
`
/**
 * Mutation to create market
 */
export const CREATE_MARKET = gql`
  mutation createMarket($market: MarketInput!) {
    createMarket(market: $market) {
      id
      title
      currency
      countries {
        name
        code
      }
    }
  }
`

/**
 * Mutation to update market
 */
export const UPDATE_MARKET = gql`
  mutation updateMarket($id: ID!, $market: MarketInput!) {
    updateMarket(id: $id, market: $market) {
      id
      title
      currency
      countries {
        name
        code
      }
    }
  }
`

/**
 * Mutation to update market prices
 */
export const UPDATE_MARKET_PRICE = gql`
  mutation updateMarketPrice($id: ID!, $input: MarketPriceInput!) {
    updateMarketPrice(id: $id, input: $input) {
      successful
    }
  }
`

/**
 * Mutation to update market countries
 */
export const UPDATE_MARKET_COUNTRIES = gql`
  mutation updateMarketCountries($id: ID!, $input: MarketCountriesInput!) {
    updateMarketCountries(id: $id, input: $input) {
      successful
    }
  }
`

/**
 * Mutation to reset market product price
 */
export const RESET_MARKET_PRODUCT_PRICE = gql`
  mutation resetMarketProductPrice($id: ID!, $productId: ID!) {
    resetMarketProductPrice(id: $id, productId: $productId) {
      successful
    }
  }
`

export const SET_DEFAULT_CURRENCY_MARKET_PRODUCT_PRICES = gql`
  mutation setDefaultCurrencyMarketProductPrices(
    $id: ID!
    $input: DefaultCurrencyMarketInput!
  ) {
    setDefaultCurrencyMarketProductPrices(id: $id, input: $input) {
      successful
    }
  }
`

export const SET_MARKET_CURRENCY_MARKET_PRODUCT_PRICES = gql`
  mutation setMarketCurrencyMarketProductPrices(
    $id: ID!
    $input: DefaultCurrencyMarketInput!
  ) {
    setMarketCurrencyMarketProductPrices(id: $id, input: $input) {
      successful
    }
  }
`
